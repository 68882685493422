<template>
    <div>
        
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    
    created() {
        this.$store.dispatch( 'auth/logout' );
    }
})
</script>